import React from 'react';
import styled from 'styled-components';

export default function User() {
    return (
        <Wrapper>
            Profile page
        </Wrapper>
    )
};


const Wrapper = styled.div`
    width:100%;
    height:100px;

`;